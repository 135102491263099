import moment from "moment";

export const updateObject = (oldObject, updatedProperties) => {
  return {
    ...oldObject,
    ...updatedProperties,
  };
};

export const classNames = (...classes) => {
  return classes.filter(Boolean).join(" ");
};

// compare function
export const sortAz = (a, b) => {
  return a.id - b.id;
};

export const sortZa = (a, b) => {
  return b.id - a.id;
};

export const getRandomNumber = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const constructPogramDetailsObject = (values, dropdownsData, isCustomProgram = false) => {
  if (values?.id) {
    var constructedObjectForApiUpdate = {
      eoplProgramRefId: values?.eoplProgramRefId,
      name: values?.name,
      type: values?.type,
      job1: values?.job1,
      tfle: values?.tfle,
      manufacturingYear: values?.manufacturingYear,
      architecturePlatform: !isCustomProgram ? values?.architecturePlatform?.id : values?.initialArchitecturePlatform,
      vehicleLine: !isCustomProgram ? values?.vehicleLine?.id : values?.initialVehicleLine,
      impact: !isCustomProgram ? values?.initialImpact : values?.impact?.id,
      inScope: !isCustomProgram ? values?.initialInScopeData : values?.inScope?.id,
      status: !isCustomProgram ? values?.initialStatus : values?.status?.id,
      pdlCheck: !isCustomProgram ? values?.initialPdlCheck : values?.pdlCheck?.id,
      engineersContact: values?.engineersContact !== "" ? values?.engineersContact : null,
      shoppingListPartsCount: values?.shoppingListPartsCount,
      sp4: values?.sp4,
      vehicleBrands: (!isCustomProgram ? values?.initialVehicleBrands : values?.vehicleBrands) ?? null,
    };
  } else {
    var constructedObjectForApiCreate = {
      eoplProgramRefId: values?.eoplProgramRefId,
      name: values?.name,
      type: values?.type,
      job1: values?.job1,
      tfle: values?.tfle,
      manufacturingYear: values?.manufacturingYear,
      architecturePlatform: values?.initialArchitecturePlatform,
      vehicleLine: values?.initialVehicleLine,
      inScope: 3,
      status: 5,
      sp4: values?.sp4,
      vehicleBrands: values?.vehicleBrands,
    };
  }

  const constructedObjectForStateUpdate = {
    eoplProgramRefId: values?.eoplProgramRefId,
    name: values?.name,
    type: values?.type,
    job1: values?.job1,
    tfle: values?.tfle,
    sp4: values?.sp4,
    manufacturingYear: values?.manufacturingYear,
    architecturePlatform: !isCustomProgram
      ? values?.architecturePlatform
      : dropdownsData.architecturePlatformList.find((element) => {
          return values?.initialArchitecturePlatform === element?.id;
        }),
    vehicleLine: !isCustomProgram
      ? values?.vehicleLine
      : dropdownsData.vehilcelineList.find((element) => {
          return values?.initialVehicleLine === element?.id;
        }),
    impact: !isCustomProgram
      ? dropdownsData.eoplImpact.find((element) => {
          return values?.initialImpact === element?.id;
        })
      : values?.impact,
    inScope: !isCustomProgram
      ? dropdownsData.inScopeStatus.find((element) => {
          return values?.initialInScopeData === element?.id;
        })
      : values?.inScope,
    status: !isCustomProgram
      ? dropdownsData.eoplStatus.find((element) => {
          return values?.initialStatus === element?.id;
        })
      : values?.status,
    pdlCheck: !isCustomProgram
      ? dropdownsData.eoplPdlCheck.find((element) => {
          return values?.initialPdlCheck === element?.id;
        })
      : values?.pdlCheck,
    vehicleBrand: !isCustomProgram
      ? dropdownsData.vehicleBrands.find((element) => {
          return values?.initialVehicleBrands === element?.id;
        }) ?? null
      : values?.vehicleBrands,
    engineersContact: values?.engineersContact,
    eoplProgramCalculation: values?.eoplProgramCalculation,
    auditUserId: values?.auditUserId,
    auditDateTime: values?.auditDateTime,
    id: values?.id,
    version: values?.version,
    shoppingListPartsCount: values?.shoppingListPartsCount,
    eoplNextQuarterReview: values?.eoplNextQuarterReview,
    shoppingListIssueDate: values?.shoppingListIssueDate,
  };

  // console.log("constructedObjectForApiUpdate", constructedObjectForApiUpdate);
  // console.log("constructedObjectForStateUpdate", constructedObjectForStateUpdate);
  // console.log("constructedObjectForApiCreate", constructedObjectForApiCreate);

  return { constructedObjectForApiUpdate, constructedObjectForStateUpdate, constructedObjectForApiCreate };
};

export const constructChildPartListObject = (item) => {
  const constructedObjectForApiUpdate = {
    eoplProgramId: item?.eoplProgramId,
    currentEpn: item?.currentEpn,
    currentApn: item?.currentApn,
    newEpn: item?.newEpn,
    partDescription: item?.partDescription,
    vendor: item?.vendor,
    changeDescription: item?.changeDescription,
    vehicleApplicability: item?.vehicleApplicability,
    backwardsCompatible: item?.backwardsCompatible,
    epn1: item?.epn1,
    apn1: item?.apn1,
    epn2: item?.epn2,
    apn2: item?.apn2,
    epn3: item?.epn3,
    apn3: item?.apn3,
    pf1: item?.pf1,
    pf2: item?.pf2,
    pf3: item?.pf3,
    iCreate: item?.iCreate,
  };

  const constructedObjectForStateUpdate = {
    eoplProgramId: item?.eoplProgramId,
    currentEpn: item?.currentEpn,
    currentApn: item?.currentApn,
    newEpn: item?.newEpn,
    partDescription: item?.partDescription,
    vendor: item?.vendor,
    changeDescription: item?.changeDescription,
    vehicleApplicability: item?.vehicleApplicability,
    backwardsCompatible: item?.backwardsCompatible,
    epn1: item?.epn1,
    apn1: item?.apn1,
    epn2: item?.epn2,
    apn2: item?.apn2,
    epn3: item?.epn3,
    apn3: item?.apn3,
    pf1: item?.pf1,
    pf2: item?.pf2,
    pf3: item?.pf3,
    auditUserId: item?.auditUserId?.id,
    auditDateTime: item?.auditDateTime,
    id: item?.id,
    version: item?.version,
    iCreate: item?.iCreate,
    vehicleBrand: item?.vehicleBrand,
  };

  return { constructedObjectForApiUpdate, constructedObjectForStateUpdate };
};

export const constructShoppingListObject = (item) => {
  const constructedObjectForApiUpdate = {
    eoplProgramId: item?.eoplProgramId,
    description: item?.description,
    epc: item?.epc,
    callOut: item?.callOut,
    pf: item?.pf,
    apn: item?.apn,
    pfApn: item?.pfApn,
    epn: item?.epn,
  };

  const constructedObjectForStateUpdate = {
    eoplProgramId: item?.eoplProgramId,
    description: item?.description,
    epc: item?.epc,
    callOut: item?.callOut,
    pf: item?.pf,
    apn: item?.apn,
    pfApn: item?.pfApn,
    epn: item?.epn,
    auditUserId: item?.auditUserId,
    auditDateTime: item?.auditDateTime,
    id: item?.id,
    version: item?.version,
  };

  return { constructedObjectForApiUpdate, constructedObjectForStateUpdate };
};

export const getFormattedDate = (dateObject, includeTime = false) => {
  const dateFormat = includeTime ? "DD/MM/YYYY hh:mm" : "DD/MM/YYYY";
  const parsedDate = moment(dateObject, moment.ISO_8601); // Parse using ISO_8601 format
  let dateData = parsedDate.format(dateFormat);
  if (dateData === "Invalid date") {
    // Handle invalid date scenario (optional)
    return dateObject;
  } else {
    return dateData;
  }
};
