import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Formik, Form } from "formik";
import FormikControl from "../../../components/ui/Formik/FormikControl";
import * as Yup from "yup";
import Button from "../../../packages/button/Button";
import { DownloadIcon } from "@heroicons/react/solid";
import { download } from "../../../store/eoplActions";
// import useToggle from "../../../packages/_utils/useToggle";
import Comments from "../../../components/comments/Comments";
import Toast from "../../../components/ui/Toast";
import { setComments, toggleCommentsRefresh } from "../../../store/eoplCommentsActions";
// import { resetComments, setComments, toggleCommentsRefresh } from "../../../store/eoplCommentsActions";
import { updateEoplProgrammeDetails } from "../../../store/eoplActions";
// import { ArrowRightIcon } from "@heroicons/react/solid";
import { constructPogramDetailsObject } from "../../../utils/utility";
import { Role } from "../../../data/roles";
// import { useNavigate } from "react-router-dom";
import NxtQuaterReviewView from "./QuarterlyReviewView";

const validationSchema = Yup.object().shape({
  // engineersContact: Yup.string().nullable().required("Required"),
  initialInScopeData: Yup.date().nullable().required("Required"),
  initialImpact: Yup.number().nullable().required("Required"),
  initialPdlCheck: Yup.string().nullable().required("Required"),
  initialStatus: Yup.number().nullable().required("Required"),
});

const MainDetails = (props) => {
  const { data, next, last, closeDrawer } = props;

  const dispatch = useDispatch();

  const currentUser = useSelector((state) => state.profile.profilesData);
  const eoplProgramData = useSelector((state) => state.eopl.data);
  const dropdownsData = useSelector((state) => state.dropdown.dropdownsData);
  const [impactOptions, setImpactOptions] = useState(dropdownsData.eoplImpact);
  const [vehicleBrandOptions, setVehicleBrandOptions] = useState(dropdownsData.vehicleBrands);
  const [pdlCheckOptions, setPdlCheckOptions] = useState(dropdownsData.eoplPdlCheck);
  const [inScopeOptions] = useState(dropdownsData.inScopeStatus);
  const [statusOptions, setStatusOptions] = useState(dropdownsData.eoplStatus);
  const [initialInScopeData, setInitialInScopeData] = useState(eoplProgramData?.inScope?.id);
  const [initialImpact, setinitialImpact] = useState(eoplProgramData?.impact?.id);
  const [initialVehicleBrands, setinitialVehicleBrands] = useState(eoplProgramData?.vehicleBrands?.id);
  const [initialPdlCheck, setInitialPdlCheck] = useState(eoplProgramData?.pdlCheck?.id);
  const [initialStatus, setInitialStatus] = useState(eoplProgramData?.status?.id);
  const refreshComments = useSelector((state) => state.eoplComments.refreshCommentsData);

  const changed = useSelector((state) => state.eopl.changed);

  useEffect(() => {
    const inScopeObject = inScopeOptions.find((element) => {
      return eoplProgramData?.inScope?.id === element?.id;
    });

    setStatusOptions(inScopeObject?.availableStatus);
    setImpactOptions(inScopeObject?.availableImpactOptions);
    setVehicleBrandOptions(dropdownsData?.vehicleBrands);
    setPdlCheckOptions(inScopeObject?.availablePdlOptions);

    setInitialInScopeData(eoplProgramData?.inScope?.id);
    setinitialImpact(eoplProgramData?.impact?.id);
    setinitialVehicleBrands(eoplProgramData?.vehicleBrands?.id);
    setInitialPdlCheck(eoplProgramData?.pdlCheck?.id);
    setInitialStatus(eoplProgramData?.status?.id);
  }, [dropdownsData?.vehicleBrands, eoplProgramData, inScopeOptions]);

  const handleInScopeDropdownChange = (value) => {
    if (value !== null) {
      const inScopeObject = inScopeOptions.find((element) => {
        return value === element?.id;
      });
      setStatusOptions(inScopeObject?.availableStatus);
      setImpactOptions(inScopeObject?.availableImpactOptions);
      setPdlCheckOptions(inScopeObject?.availablePdlOptions);
      // setInitialInScopeData(inScopeObject?.id);
      // setinitialImpact(inScopeObject?.availableImpactOptions[0]?.id);
      // setInitialPdlCheck(inScopeObject?.availablePdlOptions[0]?.id);
      // setInitialStatus(inScopeObject?.availableStatus[0]?.id);
    }
  };

  const handleSubmit = (values) => {
    dispatch(toggleCommentsRefresh(false));
    const customProgarmDetailsObj = constructPogramDetailsObject(values, dropdownsData);

    dispatch(updateEoplProgrammeDetails(values.id, customProgarmDetailsObj)).then(() => {
      if (closeDrawer) closeDrawer();
    });
  };

  const handleNext = (values) => {
    next(values);
  };
  const handleLast = (values) => {
    last(values);
  };

  const isReadOnly = true;

  // To avoid reset of comments during switching between main details and inspection screens
  if (refreshComments) {
    setTimeout(() => {
      dispatch(setComments(data.comments));
    }, 1000);
  }

  const excelDownloadHandler = () => dispatch(download("programListExport", data.eoplProgramRefId));

  return (
    <>
      <Formik
        initialValues={{
          ...eoplProgramData,
          initialInScopeData,
          initialImpact,
          initialVehicleBrands,
          initialPdlCheck,
          initialStatus,
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize={true}
        validateOnMount
        id="eoplProgramDetails"
      >
        {({ submitForm, errors, handleBlur, touched, dirty, values, setFieldTouched, setFieldValue, handleChange }) => {
          // Track the dirty state outside the render phase
          // handleDirtyChange(dirty);

          return (
            <>
              <Form>
                <div className="px-4 pt-4 bg-primary/30 sm:pt-6 sm:pXb-4 ">
                  <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-6 xl:grid-cols-11 2xl:grid-cols-12 gap-x-6 ">
                    <FormikControl
                      className="pb-4 !col-span-2 md:!col-span-2 lg:!col-span-3 xl:!col-span-6 2xl:!col-span-3"
                      control="input"
                      type="text"
                      label="Programme Reference"
                      name="eoplProgramRefId"
                      disabled={isReadOnly}
                    />
                    <FormikControl
                      className="pb-4 !col-span-2 sm:!col-span-1 lg:!col-span-2 xl:!col-span-3"
                      control="input"
                      type="text"
                      label="Programme Title"
                      name="name"
                      disabled={isReadOnly}
                    />
                    <FormikControl
                      className="pb-4 !col-span-2 sm:!col-span-1 lg:!col-span-2 xl:!col-span-2"
                      control="input"
                      type="text"
                      label="Type"
                      name="type"
                      disabled={isReadOnly}
                    />
                    <FormikControl
                      className="pb-4 !col-span-2 sm:!col-span-1 lg:!col-span-2 xl:!col-span-2"
                      control="date"
                      type="date"
                      label="J1"
                      name="job1"
                      disabled={isReadOnly}
                    />
                    <FormikControl
                      className="pb-4 !col-span-1 sm:!col-span-1 lg:!col-span-1 xl:!col-span-1"
                      control="input"
                      type="number"
                      label="TFLE"
                      name="tfle"
                      disabled={isReadOnly}
                    />
                    <FormikControl
                      className="pb-4 !col-span-1 sm:!col-span-1 lg:!col-span-1 xl:!col-span-1"
                      control="input"
                      type="text"
                      label="S4P"
                      name="sp4"
                      // dirty={dirty}
                      disabled={currentUser.role === Role.view}
                    />
                    <FormikControl
                      className="pb-4 !col-span-2 sm:!col-span-1 lg:!col-span-2 xl:!col-span-2"
                      control="select"
                      label="Brand"
                      name="initialVehicleBrands"
                      isMulti={false}
                      // dirty={dirty}
                      options={vehicleBrandOptions?.map((element) => ({
                        value: element?.id,
                        label: element?.name,
                      }))}
                      onBlur={handleBlur}
                      disabled={currentUser.role === Role.view}
                    />
                    <FormikControl
                      className="pb-4 !col-span-2 sm:!col-span-1 lg:!col-span-2 xl:!col-span-2"
                      control="input"
                      type="text"
                      label="MY"
                      name="manufacturingYear"
                      disabled={isReadOnly}
                    />
                    <FormikControl
                      className="pb-4 !col-span-2 sm:!col-span-1 lg:!col-span-2 xl:!col-span-2"
                      control="input"
                      type="text"
                      label="VL"
                      name="vehicleLine.name"
                      disabled={isReadOnly}
                    />
                    <FormikControl
                      className="pb-4 !col-span-1 sm:!col-span-1 lg:!col-span-1 xl:!col-span-1"
                      control="input"
                      type="text"
                      label="ARCH"
                      name="architecturePlatform.name"
                      disabled={isReadOnly}
                    />
                    <FormikControl
                      className="pb-4 !col-span-1 sm:!col-span-1 lg:!col-span-1 xl:!col-span-1"
                      control="input"
                      type="text"
                      label="Version"
                      name="version"
                      disabled={isReadOnly}
                    />
                    <FormikControl
                      className="pb-4 !col-span-2 sm:!col-span-1 lg:!col-span-2 xl:!col-span-2"
                      control="input"
                      type="text"
                      label="Created By"
                      name="auditUserId.name"
                      disabled={isReadOnly}
                    />
                    <FormikControl
                      className="pb-4 !col-span-2 sm:!col-span-1 lg:!col-span-2 xl:!col-span-2"
                      control="date"
                      type="date"
                      label="Created Date"
                      name="auditDateTime"
                      disabled={isReadOnly}
                    />
                    <FormikControl
                      className="pb-4 !col-span-2 sm:!col-span-1 lg:!col-span-2 xl:!col-span-2"
                      control="input"
                      type="number"
                      dirty={dirty}
                      label="Initial Shopping List Part Count"
                      name="shoppingListPartsCount"
                      disabled={currentUser.role === Role.view}
                    />
                    <FormikControl
                      className="pb-4 !col-span-2 sm:!col-span-1 lg:!col-span-2 xl:!col-span-2"
                      control="input"
                      type="text"
                      label="Engineer Contacts"
                      dirty={dirty}
                      name="engineersContact"
                      onBlur={handleBlur} // This apparently updates `touched`?
                      disabled={currentUser.role === Role.view}
                    />
                    <FormikControl
                      className="pb-4 !col-span-2 sm:!col-span-1 lg:!col-span-2 xl:!col-span-2"
                      control="select"
                      label="EoPL In-Scope"
                      name="initialInScopeData"
                      isMulti={false}
                      dirty={dirty}
                      options={inScopeOptions?.map((element) => ({
                        value: element?.id,
                        label: element?.name,
                      }))}
                      isCustomHandler={true}
                      customHandlerCallback={handleInScopeDropdownChange}
                      onBlur={handleBlur}
                      disabled={currentUser.role === Role.view}
                      onChange={(e) => {
                        handleChange(e);
                        const inScopeObject = inScopeOptions.find((element) => {
                          return e.target.value === element?.id;
                        });
                        setFieldValue(`${initialInScopeData}`, inScopeObject?.id);
                        setFieldValue(`${initialImpact}`, inScopeObject?.availableImpactOptions[0]?.id);
                        setFieldValue(`${initialPdlCheck}`, inScopeObject?.availablePdlOptions[0]?.id);
                        setFieldValue(`${initialStatus}`, inScopeObject?.availableStatus[0]?.id);
                      }}
                    />
                    <FormikControl
                      className="pb-4 !col-span-2 sm:!col-span-1 lg:!col-span-2 xl:!col-span-2"
                      control="select"
                      label="PDL Check"
                      name="initialPdlCheck"
                      dirty={dirty}
                      isMulti={false}
                      options={pdlCheckOptions?.map((element) => ({
                        value: element?.id,
                        label: element?.name,
                      }))}
                      onBlur={handleBlur}
                      disabled={currentUser.role === Role.view}
                    />
                    <FormikControl
                      className="pb-4 !col-span-2 sm:!col-span-1 lg:!col-span-2 xl:!col-span-2"
                      control="select"
                      label="EoPL Impact"
                      name="initialImpact"
                      isMulti={false}
                      dirty={dirty}
                      options={impactOptions?.map((element) => ({
                        value: element?.id,
                        label: element?.name,
                      }))}
                      onBlur={handleBlur}
                      disabled={currentUser.role === Role.view}
                    />
                    <FormikControl
                      className="pb-4 !col-span-2 sm:!col-span-1 lg:!col-span-2 xl:!col-span-2"
                      control="select"
                      label="EoPL Status"
                      name="initialStatus"
                      dirty={dirty}
                      isMulti={false}
                      options={statusOptions?.map((element) => ({
                        value: element?.id,
                        label: element?.name,
                      }))}
                      onBlur={handleBlur}
                      disabled={currentUser.role === Role.view}
                    />
                  </div>
                  <b>Programme:</b>
                  <hr></hr>
                  <br></br>
                  <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-6 xl:grid-cols-11 2xl:grid-cols-12 gap-x-6 ">
                    <FormikControl
                      className="pb-4 !col-span-2 sm:!col-span-1 lg:!col-span-2 xl:!col-span-3"
                      control="date"
                      type="date"
                      label="EoPL Start"
                      name="eoplProgramCalculation[0].eoplStart"
                      disabled={isReadOnly}
                    />
                    <FormikControl
                      className="pb-4 !col-span-2 sm:!col-span-1 lg:!col-span-2 xl:!col-span-3"
                      control="date"
                      type="date"
                      label="EoPL Aftercare End"
                      name="eoplProgramCalculation[0].eoplAftercare"
                      disabled={isReadOnly}
                    />
                    <FormikControl
                      className="pb-4 !col-span-2 sm:!col-span-1 lg:!col-span-2 xl:!col-span-3"
                      control="input"
                      type="text"
                      label="Days until Job Last / J1"
                      name="eoplProgramCalculation[0].jobLastDays"
                      disabled={isReadOnly}
                    />
                    <FormikControl
                      className="pb-4 !col-span-2 sm:!col-span-1 lg:!col-span-2 xl:!col-span-3"
                      control="date"
                      type="date"
                      label="Shopping List Issue Date"
                      name="shoppingListIssueDate"
                      disabled={true}
                    />
                  </div>
                  <b>Quarterly Review Dates:</b>
                  <hr></hr>
                  <br></br>
                  <NxtQuaterReviewView />
                </div>
              </Form>
              <hr />
              {data.id && currentUser.role !== Role.view && <Comments commentType="headerComment" commentsData={data.comments} eoplRequestId={data.id} />}
              <div className="grid grid-cols-3 gap-4px-4 py-3 bg-white text-left sm:px-6 rounded-b">
                <>
                  {((currentUser.role === Role.view && data.shoppingListIssueDate !== null) || currentUser.role !== Role.view) && (
                    <div className={`flex gap-x-2 text-sm`}>
                      <Button
                        className="bg-primary col-start-3 place-self-end flex items-center !px-2"
                        tippy="Download"
                        color="primary"
                        onClick={() => {
                          excelDownloadHandler();
                        }}
                      >
                        Download Programme Information &nbsp;
                        <DownloadIcon className="h-4 w-4" />
                      </Button>
                    </div>
                  )}
                  {currentUser.role !== Role.view && data?.eoplProgramRefId && (
                    <Button
                      onClick={() => {
                        if (Object.keys(errors).length === 0) return handleSubmit(values);
                        else {
                          Toast({
                            status: "warning",
                            message: "Please fill required data",
                          });
                          Object.keys(errors).forEach((err) => {
                            setFieldTouched(err);
                          });
                        }
                      }}
                      color="success"
                      className={`place-self-center col-start-2`}
                      type="submit"
                      disabled = {!changed}
                    >
                      Save
                    </Button>
                  )}
                </>
                {((currentUser.role === Role.view && data.shoppingListIssueDate !== null) || currentUser.role !== Role.view) && (
                  <Button
                    type="button"
                    style={{ margin: "2px" }}
                    onClick={() => {
                      if (Object.keys(errors).length === 0 && (Object.keys(touched).length === 0 || !changed)) return handleLast(values);
                      else if (Object.keys(errors).length > 0) {
                        Object.keys(errors).forEach((err) => {
                          setFieldTouched(err);
                        });
                      } else if (Object.keys(touched).length > 0 && changed) {
                        Toast({
                          status: "warning",
                          message: "Please save the programme data",
                        });
                        Object.keys(touched).forEach((err) => {
                          setFieldTouched(err);
                        });
                      }
                    }}
                    className={`bg-primary col-start-3 place-self-end flex items-center`}
                  >
                    Child Parts List
                  </Button>
                )}
                {((currentUser.role === Role.view && data.shoppingListIssueDate !== null) || currentUser.role !== Role.view) && (
                  <Button
                    type="button"
                    style={{ margin: "2px" }}
                    onClick={() => {
                      if (Object.keys(errors).length === 0 && (Object.keys(touched).length === 0 || !changed)) return handleNext(values);
                      else if (Object.keys(errors).length > 0) {
                        Object.keys(errors).forEach((err) => {
                          setFieldTouched(err);
                        });
                      } else if (Object.keys(touched).length > 0 && changed) {
                        Toast({
                          status: "warning",
                          message: "Please save the programme data",
                        });
                        Object.keys(touched).forEach((err) => {
                          setFieldTouched(err);
                        });
                      }
                    }}
                    className={`bg-primary col-start-4 place-self-end flex items-center`}
                  >
                    Shopping List
                  </Button>
                )}
              </div>
            </>
          );
        }}
      </Formik>
    </>
  );
};

export default MainDetails;